const state = {
  mqttConnected: false
}

const getters = {
  isConnected: state => {
    return state.mqttConnected
  }
}

const actions = {
  updateMqttStatus({ commit }, connected) {
    commit('updateMqttStatus', connected)
  }
}

const mutations = {
  updateMqttStatus(state, connected) {
    state.mqttConnected = connected
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
