<template>
  <div>
    <top-bar></top-bar>
    <tab-list></tab-list>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue'
import TabList from '@/components/TabList.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    TopBar,
    TabList
  },
  methods: {
    ...mapActions('auth', ['refreshVirtualBrand'])
  },
  async mounted() {
    await this.refreshVirtualBrand()
  }
}
</script>

<style></style>
