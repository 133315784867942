<template>
  <div id="app" :style="cssProps">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState('auth', ['virtualBrand']),
    accentColor() {
      return this.virtualBrand?.accentColor || '#FF7878'
    },
    secondaryColor() {
      return this.virtualBrand?.secondaryColor || '#545383'
    },
    cssProps() {
      return {
        '--accent-color': this.accentColor,
        '--accent-color-dark': this.darken(this.accentColor),
        '--secondary-color': this.secondaryColor,
        '--secondary-color-dark': this.darken(this.secondaryColor, 60),
        '--secondary-color-light': this.darken(this.secondaryColor, -150)
      }
    }
  },
  methods: {
    darken(color, percent = 10) {
      var R = parseInt(color.substring(1, 3), 16)
      var G = parseInt(color.substring(3, 5), 16)
      var B = parseInt(color.substring(5, 7), 16)
      R = Math.min(parseInt((R * (100 - percent)) / 100), 255)
      G = Math.min(parseInt((G * (100 - percent)) / 100), 255)
      B = Math.min(parseInt((B * (100 - percent)) / 100), 255)

      var RR =
        R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
      var GG =
        G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
      var BB =
        B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

      return '#' + RR + GG + BB
    }
  }
}
</script>

<style></style>
