import moment from 'moment'

const filters = {
  time(value) {
    if (value) {
      return moment(value).format('HH:mm') + 'h'
    }
  },
  elapsedMinutesAndSeconds(value) {
    if (value) {
      var now = moment(new Date())
      var start = moment(value)
      var duration = moment.duration(now.diff(start))
      return (
        Math.floor(duration.asMinutes()) +
        moment.utc(duration.asMilliseconds()).format(':ss')
      )
    }
  }
}

export default filters
