<template>
  <div class="p-4 w-full">
    <div
      class="rounded-big shadow bg-white h-full relative"
      :class="{ 'overflow-hidden': !overflowVisible }"
    >
      <div v-if="title" class="flex justify-between px-8 pt-6 items-center">
        <h2 class="text-blue font-title font-bold">{{ title }}</h2>
        <slot name="top-right" />
      </div>
      <div
        v-if="description"
        class="px-8 pt-3 items-center text-sm text-gray-350 font-body"
      >
        {{ description }}
      </div>
      <div :class="{ 'p-8': contentPadding }">
        <slot />
      </div>
      <div
        v-if="loading"
        class="absolute top-0 left-0 bottom-0 right-0 transparent-background z-40 flex items-center justify-center"
      >
        <l-loading-spinner :size="'medium'" />
      </div>
    </div>
  </div>
</template>

<script>
import LLoadingSpinner from '@last/core-ui/v3/components/LLoadingSpinner'

export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    contentPadding: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    overflowVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LLoadingSpinner
  }
}
</script>

<style scoped>
.transparent-background {
  background-color: rgba(30, 32, 31, 0.5);
}
</style>
