<template>
  <div
    :class="{
      disabled: disabled,
      main: type === 'main',
      adminMain: type === 'adminMain',
      secondary: type === 'secondary'
    }"
    class="flex justify-center text-white text-lg uppercase px-10 py-3 border rounded-small font-bold done-button flex items-center shadow-lg relative cursor-pointer"
  >
    {{ label }}
    <l-loading-spinner v-show="loading" class="loading" />
  </div>
</template>

<script>
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner'

export default {
  components: {
    LLoadingSpinner
  },
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'main'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  @apply bg-red border-red-b;
}
.secondary {
  @apply bg-blue border-blue-600;
}
.adminMain {
  @apply bg-violet border-violet-b;
}

.loading {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
