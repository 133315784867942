<template>
  <div class="mt-10 cursor-default">
    <div class="w-full flex items-center justify-center">
      <div class="w-1/3 relative">
        <div class="flex items-center">
          <icon
            v-if="isKitchenCompleted"
            name="done"
            class="text-accent absolute left-0"
          />
          <div
            v-else-if="isKitchen"
            class="flex items-center justify-center rounded-full w-10 h-8 text-white bg-accent"
          >
            1
          </div>
          <div
            class="h-1 w-1/2 bg-accent"
            :class="{
              'bg-accent': isReadyToPickupCompleted,
              'ml-6': isKitchenCompleted,
              'bg-accent': isReadyToPickup
            }"
          ></div>
          <icon
            v-if="isReadyToPickupCompleted"
            name="done"
            class="text-accent inset-x-1/2"
          />
          <div
            v-else
            class="flex items-center justify-center rounded-full w-7 h-6 text-white bg-accent"
            :class="{ 'w-10 h-8 bg-accent': isReadyToPickup }"
          >
            2
          </div>
          <div
            class="h-1 w-1/2 bg-accent"
            :class="{ 'bg-accent mr-6': isFinished }"
          ></div>
          <icon
            v-if="isFinished"
            name="done"
            class="text-accent absolute right-0"
          />
          <div
            v-else
            class="flex items-center justify-center rounded-full w-7 h-6 text-white bg-accent"
          >
            3
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex items-center justify-center mt-4">
      <div class="w-1/3 relative">
        <div class="flex items-center justify-between">
          <div
            class="flex flex-1 -ml-4 text-accent"
            :class="{
              'font-bold': isKitchen,
              'text-accent': isKitchenCompleted
            }"
          >
            <div class="uppercase">{{ $t('order.kitchen_status') }}</div>
            <div
              class="absolute mt-6 font-normal"
              :class="{
                'text-red -ml-8': isKitchenCompleted
              }"
            >
              {{ $filters.time(findStatus('KITCHEN')?.creationTime) || '' }}
              {{ minutesBetween('KITCHEN', 'READY_TO_PICKUP') }}
            </div>
          </div>
          <div
            class="flex flex-1 justify-center -ml-28 text-accent"
            :class="{
              'text-accent': isReadyToPickupCompleted,
              'font-bold': isReadyToPickup,
              'border border-accent p-2 rounded-lg cursor-pointer': isKitchen
            }"
            @click="markAsReadyToPickUp"
          >
            <div class="uppercase">
              {{ $t('order.ready_to_pickup_status') }}
            </div>
            <div
              class="absolute mt-6 text-accent font-normal"
              :class="{
                'text-red -mr-18': isReadyToPickupCompleted
              }"
              @click="markAsReadyToPickUp"
            >
              {{
                $filters.time(findStatus('READY_TO_PICKUP')?.creationTime) || ''
              }}
              {{ minutesBetween('READY_TO_PICKUP', 'ON_DELIVERY') }}
            </div>
          </div>
          <div
            class="flex flex-1 justify-center -mr-36 text-gray-300"
            :class="{
              'text-accent': isFinished,
              'border border-accent text-accent p-2 rounded-lg cursor-pointer':
                isReadyToPickup
            }"
            @click="markAsDelivered"
          >
            <div class="uppercase">{{ $t('order.delivered_status') }}</div>
            <div
              class="absolute mt-6 text-gray-300"
              :class="{
                'text-accent': isReadyToPickup,
                'text-red': isFinished
              }"
            >
              {{ $filters.time(findStatus('ON_DELIVERY')?.creationTime) || '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/v3/components/Icon.vue'
import moment from 'moment'
export default {
  name: 'TabStatus',
  components: {
    Icon
  },
  props: {
    tabId: {
      type: String,
      required: true
    },
    statuses: {
      type: Array,
      default: () => []
    }
  },
  emits: ['statusUpdated'],
  methods: {
    async markAsReadyToPickUp() {
      if (this.currentStatus?.name === 'KITCHEN') {
        this.$emit('statusUpdated', 'READY_TO_PICKUP')
      }
    },
    async markAsDelivered() {
      if (this.currentStatus?.name === 'READY_TO_PICKUP') {
        this.$emit('statusUpdated', 'ON_DELIVERY')
      }
    },
    findStatus(name) {
      return this.statuses.find(s => s.name === name)
    },
    minutesBetween(statusName1, statusName2) {
      let status1 = this.findStatus(statusName1)
      let status2 = this.findStatus(statusName2)
      if (status1 && status2) {
        let minutesDiff = moment(status2.creationTime).diff(
          moment(status1.creationTime),
          'minutes'
        )
        return (
          '(' + Math.floor(minutesDiff).toString().padStart(2, '0') + 'min)'
        )
      }
      return ''
    }
  },
  computed: {
    currentStatus() {
      return this.statuses[0]
    },
    isKitchen() {
      return this.currentStatus?.name === 'KITCHEN'
    },
    isKitchenCompleted() {
      return (
        this.currentStatus?.name !== 'KITCHEN' &&
        this.statuses.some(s => s.name === 'KITCHEN')
      )
    },
    isReadyToPickupCompleted() {
      return (
        this.currentStatus?.name !== 'READY_TO_PICKUP' &&
        this.statuses.some(s => s.name === 'READY_TO_PICKUP')
      )
    },
    isReadyToPickup() {
      return this.currentStatus?.name === 'READY_TO_PICKUP'
    },
    isFinished() {
      return this.statuses.some(s =>
        ['ON_DELIVERY', 'DELIVERED'].includes(s.name)
      )
    }
  }
}
</script>

<style scoped></style>
