import api from '@/api.js'

const state = {
  tabs: []
}

const getters = {
  tabs: state => state.tabs
}

const actions = {
  async refreshTabs({ commit, rootGetters }) {
    if (
      !rootGetters['auth/isAuthenticated'] ||
      !rootGetters['auth/selectedLocationGroupId']
    )
      return
    let response = await api.get('/riders/tabs')
    commit('setTabs', response.data.tabs)
  }
}

const mutations = {
  setTabs(state, tabs) {
    state.tabs = tabs
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
