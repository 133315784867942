import mqtt from 'mqtt'
import store from '@/store/index.js'

var client = mqtt.connect(process.env.VUE_APP_MQTT_URL)

function subscribe(locationGroupId) {
  let topic = process.env.VUE_APP_MQTT_TOPIC + '-lg-' + locationGroupId
  client.subscribe(topic, () => {
    store.dispatch('status/updateMqttStatus', true)
  })
}

client.on('connect', function () {
  let locationGroupId = store && store.state.auth.selectedLocationGroupId
  if (locationGroupId) {
    subscribe(locationGroupId)
  }
})

client.on('error', function (error) {
  window.console.log('error ', error)
})

client.on('end', function () {
  store.dispatch('status/updateMqttStatus', false)
})

client.on('offline', function () {
  store.dispatch('status/updateMqttStatus', false)
})

client.on('disconnect', function () {
  store.dispatch('status/updateMqttStatus', false)
})

client.on('message', function (topic, data) {
  let message = JSON.parse(data)
  if (message.type === 'tabsUpdated') {
    store.dispatch('tabs/refreshTabs', message.tabs)
  }
})

export default {
  subscribe: subscribe,
  isConnected: client.connected
}
