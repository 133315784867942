<template>
  <div id="topBar">
    <div
      class="px-4 py-3 flex text-white items-center h-14"
      :style="{ 'background-color': bgColor }"
    >
      <div class="flex-1 flex items-center justify-center">
        <l-image
          mode="fit"
          v-if="imageId"
          :image-id="imageId"
          class="logo h-full bg-contain bg-left"
          :width="200"
          :height="56"
        />
        <img v-else src="@/assets/logo-light.svg" class="h-8" />
      </div>
    </div>
    <div class="py-1 bg-red text-white flex justify-center" v-if="!isConnected">
      <icon name="loading-error" class="mr-2 flex-shrink-0" />
      {{ $t('topbar.internet-issues') }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/v3/components/Icon.vue'
import LImage from '@last/core-ui/components/LImage.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'TopBar',
  components: {
    Icon,
    LImage
  },
  computed: {
    ...mapGetters('status', ['isConnected']),
    ...mapState('auth', ['virtualBrand']),
    bgColor() {
      return this.virtualBrand?.topbarColor || '#545383'
    },
    imageId() {
      return this.virtualBrand?.colorLogoImageId
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 200px;
  height: 56px;
}
</style>
