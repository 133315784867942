import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/components/Home.vue'
import LocationGroupSelector from '@/components/LocationGroupSelector.vue'
import Login from '@/components/Login.vue'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    redirect: { name: 'locationGroupSelector' }
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
    props: { mode: 'display' }
  },
  {
    name: 'dispatcher',
    path: '/dispatcher',
    component: Home,
    props: { mode: 'dispatcher' }
  },
  {
    name: 'locationGroupSelector',
    path: '/select',
    component: LocationGroupSelector
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  { path: '/:catchAll(.*)', redirect: '/select' }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let needsAuth = !['login'].includes(to.name)
  let isAuthenticated = store.getters['auth/isAuthenticated']
  if (needsAuth && !isAuthenticated) {
    next({
      name: 'login'
    })
  } else {
    next()
  }
})

export default router
