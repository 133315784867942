<template>
  <div
    :class="{
      'bg-light-red': isCancelled,
      'bg-gray-200': expandedStatus
    }"
  >
    <div
      class="relative max-height-70 flex text-gray-350 p-2"
      :class="{
        'border-b border-gray-100': !expandedStatus,
        'cursor-pointer': mode === 'dispatcher'
      }"
      @click="expandStatus"
    >
      <div
        v-if="firstInBundle"
        class="bg-secondary h-6 w-6 rounded-full flex justify-center items-center absolute top-0 ml-1 mt-3"
      >
        <icon name="chain" class="text-white" small />
      </div>
      <div class="flex ml-12 justify-center items-center">
        <div>
          <img
            v-if="getLogoImage"
            class="max-width-50 max-height-70"
            :src="getLogoImage"
          />
          <div v-else style="width: 50px; height: 70px"></div>
        </div>
      </div>
      <div
        class="flex justify-center flex-1 items-center text-2xl font-semibold"
        v-if="!isCancelled"
        :class="{
          'text-green': isReadyForPickUp,
          'text-red': !isReadyForPickUp
        }"
      >
        <div>{{ elapsedTime }}</div>
        <div class="ml-3">
          {{ tabStatus }}
        </div>
      </div>
      <div
        class="flex justify-center flex-1 items-center text-2xl font-semibold text-secondary uppercase"
        v-else-if="isCancelled"
        v-t="'order.cancelled_status'"
      />
      <div
        class="flex justify-center flex-1 text-secondary items-center text-3xl font-medium"
      >
        {{ tab.code?.toUpperCase() }}
      </div>
      <div
        class="flex items-center text-center justify-center flex-1 text-secondary text-2xl"
      >
        {{ tab.brandName?.toUpperCase().substr(0, 25) }}
      </div>
      <div
        class="flex items-center text-center justify-center flex-1 text-secondary text-2xl font-semibold"
      >
        {{ tab.customerName?.toUpperCase().substr(0, 25) }}
      </div>
      <div
        v-if="expandedStatus"
        class="absolute top-0 right-0 mr-6 mt-5"
        @click="removeTab"
      >
        <icon name="trash" class="cursor-pointer text-secondary" />
      </div>
    </div>
    <div v-if="expandedStatus && !isCancelled" class="h-36">
      <tab-status
        :statuses="tab.statusHistory"
        :tab-id="tab.tabId"
        @status-updated="updateStatus"
      ></tab-status>
    </div>
  </div>
</template>

<script>
import TabStatus from '@/components/TabStatus.vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
const SUPPORTED_SOURCES = [
  'glovo',
  'uber',
  'deliveroo',
  'stuart',
  'justeat',
  'shargo',
  'shop'
]
const SUPPORTED_TAB_STATUSES = ['READY_TO_PICKUP', 'KITCHEN']
export default {
  name: 'TabRow',
  props: {
    tab: {
      type: Object,
      required: true
    },
    firstInBundle: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      elapsedTime: '',
      screenCreatedTime: '',
      interval: null,
      expandedStatus: false
    }
  },
  emits: ['remove', 'statusUpdated'],
  unmounted() {
    clearInterval(this.interval)
  },
  created() {
    this.screenCreatedTime = new Date()
    this.interval = setInterval(() => {
      this.elapsedTime = this.$filters.elapsedMinutesAndSeconds(
        this.tab.activationTime
      )
    }, 1000)
  },
  computed: {
    getLogoImage() {
      let lowercaseSource = this.tab?.source?.toLowerCase()
      try {
        if (SUPPORTED_SOURCES.includes(lowercaseSource)) {
          return require(`@/assets/logo_${lowercaseSource}.png`)
        }
        return null
      } catch (err) {
        return null
      }
    },
    isReadyForPickUp() {
      return this.tab.status?.name?.toUpperCase() === 'READY_TO_PICKUP'
    },
    isCancelled() {
      return this.tab.cancelTime
    },
    tabStatus() {
      return SUPPORTED_TAB_STATUSES.includes(
        this.tab?.status?.name?.toUpperCase()
      )
        ? this.$t(
            `order.${this.tab?.status?.name?.toLowerCase()}_status`
          )?.toUpperCase() || ''
        : this.tab?.status?.name?.toUpperCase()
    }
  },
  methods: {
    expandStatus() {
      if (this.mode === 'dispatcher') {
        this.expandedStatus = !this.expandedStatus
      }
    },
    removeTab() {
      this.$emit('remove', this.tab.tabId)
    },
    updateStatus(newStatus) {
      this.$emit('statusUpdated', newStatus)
    }
  },
  components: {
    Icon,
    TabStatus
  }
}
</script>

<style scoped>
.max-width-50 {
  max-width: 50px;
}
.max-height-70 {
  max-height: 70px;
}
</style>
