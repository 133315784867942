import api from '@/api.js'
import store from '@/store/index.js'
import mqtt from '@/sync/mqtt.js'

window.addEventListener('storage', e => {
  if (e.key === 'accessToken' && !localStorage.getItem('accessToken')) {
    store.dispatch('auth/logout', e.newValue)
  }
})

const state = {
  accessToken: localStorage.getItem('accessToken'),
  selectedLocationGroupId: localStorage.getItem('selectedLocationGroupId'),
  virtualBrand: localStorage.getItem('virtualBrand')
}

const getters = {
  isAuthenticated: state => !!state.accessToken,
  selectedLocationGroupId: state => state.selectedLocationGroupId
}

const actions = {
  async login({ commit }, loginData) {
    let response = await api.post('/riders/users/login', loginData)
    commit('setAccessToken', response.data.token)
  },
  async logout({ commit }) {
    await api.post('/users/logout')
    commit('logout')
  },
  async selectLocationGroup({ commit, dispatch }, locationGroup) {
    mqtt.subscribe(locationGroup.id)
    commit('setLocationGroupId', locationGroup.id)
    await store.dispatch('tabs/refreshTabs')
    await dispatch('refreshVirtualBrand')
  },
  async refreshVirtualBrand({ commit, state }) {
    let response = await api.get(
      `/riders/location-group/${state.selectedLocationGroupId}/get-brand`
    )
    commit('setVirtualBrand', response.data)
  }
}

const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token
    localStorage.setItem('accessToken', token)
  },
  logout(state) {
    state.accessToken = null
    state.selectedLocationGroupId = null
    localStorage.clear()
  },
  setLocationGroupId(state, locationGroupId) {
    state.selectedLocationGroupId = locationGroupId
    localStorage.setItem('selectedLocationGroupId', locationGroupId)
  },
  setVirtualBrand(state, virtualBrand) {
    state.virtualBrand = virtualBrand
    localStorage.setItem('virtualBrand', virtualBrand)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
