<template>
  <l-modal class="mt-20" @close="$emit('close')">
    <template #body>
      <div class="flex items-center justify-center">
        <icon name="trash" class="text-red" />
      </div>
      <div class="flex items-center justify-center mt-6">
        <h2
          v-t="'order.removal-question'"
          class="text-secondary font-title font-bold uppercase"
        ></h2>
      </div>

      <l-modal-ctas>
        <l-modal-button
          type="secondary"
          :label="$t('order.cancel')"
          @click.native="$emit('close')"
          class="flex-1 mr-4 bg-violet"
        />
        <l-modal-button
          type="main"
          :label="$t('order.delete')"
          class="flex-1"
          :loading="loading"
          @click.native="markTabAsClosed"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/v3/components/LModal'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import LModalCtas from '@last/core-ui/v3/components/LModalCtas'
import LModalButton from '@last/core-ui/v3/components/LModalButton'

export default {
  name: 'RemoveTabModal',
  props: {
    tabId: {
      type: String,
      default: null
    }
  },
  emits: ['close', 'statusUpdated'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    markTabAsClosed() {
      this.$emit('statusUpdated', 'CLOSED')
      this.$emit('close')
    }
  },
  components: {
    LModal,
    Icon,
    LModalCtas,
    LModalButton
  }
}
</script>

<style scoped></style>
