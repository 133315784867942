<template>
  <div>
    <div class="pt-5 pb-3 pl-10 sticky top-0 bg-white z-50">
      <div class="flex text-gray-400 p-3">
        <div v-t="'order.source'" class="flex justify-center" />
        <div v-t="'order.status'" class="flex flex-1 justify-center" />
        <div v-t="'order.code'" class="flex flex-1 justify-center" />
        <div v-t="'order.brand'" class="flex flex-1 justify-center" />
        <div v-t="'order.customer'" class="flex flex-1 justify-center" />
      </div>
    </div>
    <div
      v-for="(shipmentBundle, index) in filteredNowTabsGrouped"
      :key="shipmentBundle[0].tabId"
    >
      <tab-row
        v-if="shipmentBundle.length === 1"
        :id="`row-${index}`"
        :tab="shipmentBundle[0]"
        :mode="mode"
        @remove="tabId => (tabIdToRemove = tabId)"
        @status-updated="
          newStatus => updateStatus(newStatus, shipmentBundle[0].tabId)
        "
      />
      <div v-else class="bg-gray-100 relative">
        <div
          v-for="(tab, indexInsideBundle) in shipmentBundle"
          :key="tab.tabId"
        >
          <tab-row
            :tab="shipmentBundle[indexInsideBundle]"
            :id="`row-${indexInsideBundle}`"
            :mode="mode"
            :first-in-bundle="indexInsideBundle === 0"
            @remove="tabId => (tabIdToRemove = tabId)"
            @status-updated="newStatus => updateStatus(newStatus, tab.tabId)"
          >
          </tab-row>
        </div>
        <div
          class="w-1 bg-secondary absolute top-0 rounded-lg bundle-bar bottom-0 mb-3 mt-10"
        />
      </div>
    </div>
    <remove-tab-modal
      v-if="tabIdToRemove"
      :tab-id="tabIdToRemove"
      @close="tabIdToRemove = null"
      @status-updated="newStatus => updateStatus(newStatus, tabIdToRemove)"
    ></remove-tab-modal>
  </div>
</template>

<script>
import moment from 'moment'
import TabRow from '@/components/TabRow.vue'
import RemoveTabModal from '@/components/RemoveTabModal.vue'
import { mapGetters, mapActions } from 'vuex'
import api from '@/api.js'
export default {
  name: 'TabList',
  data: function () {
    return {
      currentScrollingIndex: 0,
      numberOfTabsPerScreen: 10,
      mode: 'display',
      tabIdToRemove: null
    }
  },
  async mounted() {
    this.mode = this.$route.name === 'dispatcher' ? 'dispatcher' : 'display'
    this.numberOfTabsPerScreen = Math.floor(window.innerHeight / 100)
    this.currentScrollingIndex += this.numberOfTabsPerScreen
    if (this.mode === 'display') {
      setInterval(() => this.scrollToElement(), 10000)
    }
  },
  computed: {
    ...mapGetters('tabs', ['tabs']),
    ...mapActions('tabs', ['refreshTabs']),
    groupedShipments() {
      let res = this.tabs.reduce((res, tab) => {
        let shipmentId = tab.shipmentId || 'null'
        res[shipmentId] = res[shipmentId] ? [...res[shipmentId], tab] : [tab]
        return res
      }, {})
      return res
    },
    filteredNowTabsGrouped() {
      return Object.keys(this.groupedShipments)
        .flatMap(key => {
          if (key === 'null' || this.groupedShipments[key].length === 1) {
            return this.groupedShipments[key].map(tab => [tab])
          } else {
            return [this.groupedShipments[key]]
          }
        })
        .sort((first, second) => {
          return (
            moment(first[0].activationTime) - moment(second[0].activationTime)
          )
        })
    }
  },
  methods: {
    scrollToElement() {
      const rowToScrollTo = document.getElementById(
        'row-' + this.currentScrollingIndex
      )
      if (rowToScrollTo) {
        rowToScrollTo.scrollIntoView({ behavior: 'smooth' })
        this.currentScrollingIndex += this.numberOfTabsPerScreen
      } else {
        this.currentScrollingIndex = this.numberOfTabsPerScreen
        const topBarElement = document.getElementById('topBar')
        topBarElement.scrollIntoView({ behavior: 'smooth' })
      }
    },
    async updateStatus(newStatus, tabId) {
      await api.put(`/riders/tabs/${tabId}/status`, {
        status: newStatus
      })
    }
  },
  components: {
    TabRow,
    RemoveTabModal
  }
}
</script>

<style lang="scss" scoped>
.bundle-bar {
  height: auto;
  margin-left: 1.4rem;
}
</style>
