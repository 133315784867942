<template>
  <div class="flex flex-col pb-4">
    <div class="font-body" :class="[labelColorClass, labelFontSizeClass]">
      {{ label }} <span v-if="mandatory" class="text-red">*</span>
    </div>
    <div class="flex">
      <slot />
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
export default {
  name: 'LField',
  props: {
    label: {
      type: String,
      default: null
    },
    labelColorClass: {
      type: String,
      default: 'text-blue'
    },
    labelFontSizeClass: {
      type: String,
      default: 'text-base'
    },
    mandatory: {
      type: Boolean,
      default: false
    }
  }
}
</script>
