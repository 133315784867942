import axios from 'axios'
import store from '@/store/index.js'

let api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

api.interceptors.request.use(
  config => {
    let token = store.state.auth.accessToken
    if (token) {
      config.headers['Authorization'] = token
    }

    let selectedLocationGroupId = store.state.auth.selectedLocationGroupId
    if (selectedLocationGroupId) {
      config.headers['Location-Group-ID'] = selectedLocationGroupId
    }

    return config
  },

  error => {
    return Promise.reject(error)
  }
)

export default api
