<template>
  <div
    class="modal transparent-background fixed top-0 left-0 h-screen w-screen z-40 sm:px-10 py-10 sm:py-20 overflow-y-scroll scrolling-touch"
    @click="!disableClickOutside && $emit('close')"
  >
    <div
      :class="{
        'modal--extra-small': size === 'extra-small',
        'modal--small': size === 'small',
        'modal--medium': size === 'medium',
        'modal--large': size === 'large'
      }"
      @click.stop
    >
      <div class="text-white uppercase sm:text-2xl text-center pb-6 font-bold">
        {{ title }}
      </div>
      <section class="modal__body p-6 sm:px-10 sm:py-10">
        <div class="p-4 absolute top-0 right-0">
          <icon
            v-if="!hiddeClose"
            name="close"
            class="text-gray-400 cursor-pointer"
            @click.native="$emit('close')"
          />
        </div>
        <slot name="body" />
      </section>
      <footer class="p-12"></footer>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
export default {
  name: 'LModal',
  emits: ['close'],
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    hiddeClose: {
      type: Boolean,
      default: false
    },
    disableClickOutside: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
  backdrop-filter: blur(0.625rem);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &__body {
    position: relative;
    background-color: white;
    border-radius: 1.25rem;
  }

  &--extra-small {
    width: 35%;
    min-width: 18.75rem;
    max-width: 37.5rem;
    height: 100%;
  }

  &--small {
    width: 50%;
    min-width: 18.75rem;
    max-width: 37.5rem;
    height: 100%;
  }
  &--medium {
    width: 70%;
    min-width: 18.75rem;
    max-width: 56.25rem;
    height: 100%;
  }
  &--large {
    width: 90%;
    min-width: 18.75rem;
    height: 100%;
  }
}
</style>
