<template>
  <div class="w-full">
    <div
      :class="{
        'border-red': wrongValue,
        'hover:border-red': wrongValue,
        'focus-within:border-red': wrongValue,
        'my-2': verticalMargin,
        'bg-white border': theme === 'light',
        'bg-transparent-gray': theme === 'dark',
        'opacity-50': disabled,
        'hover:border-gray-400': !disabled
      }"
      class="w-full text-blue border-gray-300 rounded-small focus-within:border-blue flex items-center"
    >
      <slot>
        <input
          v-if="inputType === 'input'"
          ref="input"
          class="appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight"
          :class="{
            'py-2 text-sm': small,
            'py-4': big,
            'py-3': !small && !big,
            'bg-transparent text-white': theme === 'dark'
          }"
          v-model="computedValue"
          v-bind="$attrs"
          :placeholder="placeholder"
          :disabled="disabled"
        />
        <textarea
          ref="input"
          v-else-if="inputType === 'textarea'"
          class="appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight"
          :class="{
            'py-2 text-sm': small,
            'py-4': big,
            'py-3': !small && !big,
            'bg-transparent text-white': theme === 'dark'
          }"
          v-model="computedValue"
          v-bind="$attrs"
          :placeholder="placeholder"
          :disabled="disabled"
        />
        <slot name="icons">
          <div class="h-full rounded-small flex items-center">
            <icon v-if="icon" :name="icon" class="mr-4" :class="iconClass" />
          </div>
        </slot>
      </slot>
    </div>
    <div v-if="wrongValue && errorMessage" class="text-xs text-red ml-4">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'LInput',
  props: {
    icon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'text-red'
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    small: Boolean,
    wrongValue: {
      type: Boolean,
      default: false
    },
    verticalMargin: {
      type: Boolean,
      default: true
    },
    big: Boolean,
    isCurrency: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    inputType: {
      type: String,
      default: 'input'
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    computedValue: {
      get() {
        return this.isCurrency ? this.modelValue / 100 : this.modelValue
      },
      set(value) {
        if (this.isCurrency) {
          let roundedValue = Math.round(String(value).replace(',', '.') * 100)
          this.$emit(
            'update:modelValue',
            isNaN(roundedValue) ? null : roundedValue
          )
        } else {
          this.$emit('update:modelValue', value)
        }
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  },
  components: {
    Icon
  }
}
</script>
