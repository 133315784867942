import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js'
import './style.scss'
import filters from './filters.js'
import i18n from './i18n.js'
import '@/sync/mqtt.js'
import { useI18n } from 'vue-i18n'
import '@/sentry.js'

const app = createApp(App, {
  setup() {
    const { t } = useI18n()
    return { t }
  }
})
app.use(router)
app.use(store)
app.use(i18n)

app.config.globalProperties.$filters = filters

app.mount('#app')

const initialize = async () => {
  await store.dispatch('tabs/refreshTabs')
}

initialize()
