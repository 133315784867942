import { createStore } from 'vuex'
import auth from './auth'
import tabs from './tabs'
import status from './status'

const debug = process.env.NODE_ENV !== 'production'
const store = createStore({
  modules: {
    auth,
    tabs,
    status
  },
  strict: debug
})

export default store
