<template>
  <div
    v-if="svg"
    class="w-4 h-4 flex items-center justify-center flex-shrink-0"
    @click="$emit('click')"
    :class="{
      'opacity-50 pointer-events-none': disabled,
      'current-color-fill': !originalColor && svgType === 'fill',
      'current-color-stroke': !originalColor && svgType === 'stroke',
      'w-6 h-6': !this.small
    }"
    v-html="svg"
  ></div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    originalColor: {
      type: Boolean,
      default: false
    },
    svgType: {
      type: String,
      default: 'fill',
      validator: function (value) {
        // The value must match one of these strings
        return ['fill', 'stroke'].indexOf(value) !== -1
      }
    }
  },
  emits: ['click'],
  computed: {
    svg() {
      try {
        return require(`!svg-inline-loader?classPrefix&idPrefix!../../components/assets/svg/${this.name}.svg`)
      } catch (e) {
        return ''
      }
    }
  }
}
</script>

<style>
.current-color-stroke path {
  stroke: currentColor;
}
.current-color-fill path {
  fill: currentColor;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
