<template>
  <l-input :type="type" :placeholder="placeholder" v-model="passwordRepeated">
    <template v-slot:icons>
      <icon
        @click.native="changeType"
        :name="iconName"
        class="mr-4"
        :class="iconClass"
      />
      <icon name="lock" class="mr-4" :class="iconClass" />
    </template>
  </l-input>
</template>

<script>
import LInput from '@last/core-ui/v3/components/LInput'
import Icon from '@last/core-ui/v3/components/Icon.vue'

export default {
  name: 'LInputPassword',
  components: {
    Icon,
    LInput
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'text-red'
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
    type: 'password'
  }),
  computed: {
    iconName() {
      return this.type === 'password' ? 'eye' : 'eye-disabled'
    },
    passwordRepeated: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    changeType() {
      this.type = this.type === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss" scoped></style>
