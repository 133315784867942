<template>
  <div class="h-screen flex flex-col justify-center bg-cover bg-image">
    <div
      class="h-full overflow-y-scroll flex justify-center"
      :class="{
        'items-center': !locationGroups || locationGroups.length <= 10
      }"
    >
      <div class="flex flex-col sm:px-24">
        <div
          class="text-white uppercase sm:text-3xl text-center pb-6 font-medium"
        >
          Location Groups
        </div>
        <div
          v-for="locationGroup in locationGroups"
          class="cursor-pointer location-group-box bg-white mb-5 rounded-big font-title text-blue text-xl font-bold flex justify-center items-center text-center"
          @click="selectLocationGroupAndContinue(locationGroup)"
          :key="locationGroup.id"
        >
          {{ locationGroup.name?.toUpperCase() }}
        </div>
        <div
          class="text-white sm:text-xl text-center pb-6 font-medium"
          v-if="locationGroups.length === 0"
          v-t="'location-group-selector.zero-groups-error'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import { mapActions } from 'vuex'

export default {
  name: 'LocationSelector',
  data() {
    return {
      locationGroups: []
    }
  },
  async mounted() {
    let response = await api.get('/riders/location-groups')
    this.locationGroups = response.data?.locationGroups || []
    if (this.locationGroups.length === 1) {
      this.selectLocationGroupAndContinue(this.locationGroups[0])
    }
  },
  methods: {
    ...mapActions('auth', ['selectLocationGroup']),
    selectLocationGroupAndContinue(locationGroup) {
      this.selectLocationGroup(locationGroup)
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-image {
  background-image: url('~@/assets/LocationGroupSelectorBackground.png');
}
.location-group-box {
  width: 360px;
  min-height: 60px;
}
</style>
